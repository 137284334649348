<template>
  <div id="topic-link">
    <KTCard>
      <template v-slot:title>
        <div style="max-width:200px">
          <h6 class="mb-0">Topic Links</h6>
        </div>
      </template>
      <template v-slot:toolbar>
        <button-add v-b-modal.topic-link-create></button-add>
        <TopicLinkDropDown
          :action-ready.sync="actionReady"
          :topic-id="selectedTopic.topicId"
        ></TopicLinkDropDown>
        <button-expand screen-id="topic-link"></button-expand>
      </template>
      <template v-slot:body>
        <h6 class="mb-5">
          <strong>{{ selectedTopic.name }}</strong>
          <p
            class="text-muted font-size-base"
            v-if="
              hourlyUpdatedCount &&
                (hourlyUpdatedCount.resolved || hourlyUpdatedCount.total)
            "
          >
            Hourly update count [{{ hourlyUpdatedCount.resolved }}/{{
              hourlyUpdatedCount.total
            }}]
          </p>
        </h6>

        <b-row class="mb-3">
          <b-col sm="3">
            <b-form-select
              size="sm"
              v-model="pagingTopicLink.perPage"
              :options="[10, 25, 50, 100]"
            >
            </b-form-select>
          </b-col>

          <b-col sm="4" offset-sm="5">
            <div class="d-flex flex-row" style="column-gap: 5px;">
              <!-- <b-select
                :options="fields"
                size="sm"
                v-model="fieldSelected"
                id="fields"
              ></b-select> -->
              <b-form-input
                size="sm"
                type="text"
                placeholder="Search"
                v-model.trim="topicLinkSearch"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>

        <b-table
          style="min-height:200px"
          bordered
          responsive
          :items="topicLinkDatas"
          :fields="topicLinkFields"
          show-empty
          :busy="!isTopicLinkLoaded"
        >
          <template v-slot:table-busy>
            <div class="text-danger my-2">
              <b-spinner class="align-middle mx-2"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template v-slot:head(source)="data">
            <b-dropdown
              ref="filter_source"
              id="filter-source"
              dropdown
              right
              variant="text"
              :toggle-class="
                filter.source !== 'all'
                  ? 'font-weight-bolder p-0 text-center text-success'
                  : 'font-weight-bolder p-0 text-center'
              "
            >
              <template #button-content> {{ data.label }}</template>
              <b-dropdown-item
                href="#"
                @click="setFilter('source', 'all')"
                :active="filter.source === 'all'"
                >All</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                @click="setFilter('source', source)"
                v-for="source in sources"
                :key="source"
                :active="filter.source === source"
                >{{ source }}</b-dropdown-item
              >
            </b-dropdown>

            <div style="width:120px"></div>
          </template>

          <template v-slot:head(isPersonal)="data">
            <b-dropdown
              id="filter-isPersonal"
              ref="filter_isPersonal"
              dropdown
              right
              variant="text"
              :toggle-class="
                filter.isPersonal !== 'all'
                  ? 'font-weight-bolder p-0 text-center text-success'
                  : 'font-weight-bolder p-0 text-center'
              "
            >
              <template #button-content> {{ data.label }}</template>
              <b-dropdown-item
                href="#"
                @click="setFilter('isPersonal', 'all')"
                :active="filter.isPersonal === 'all'"
                >All</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                @click="setFilter('isPersonal', true)"
                :active="filter.isPersonal === true"
                >Personal</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                @click="setFilter('isPersonal', false)"
                :active="filter.isPersonal === false"
                >Non Personal</b-dropdown-item
              >
            </b-dropdown>

            <div style="width:120px"></div>
          </template>

          <template v-slot:head(username)="data">
            <b-dropdown
              ref="filter_username"
              id="filter-username"
              dropdown
              right
              variant="text"
              :toggle-class="
                filter.username !== ''
                  ? 'font-weight-bolder p-0 text-center text-success'
                  : 'font-weight-bolder p-0 text-center'
              "
            >
              <template #button-content> {{ data.label }}</template>
              <b-dropdown-form style="width:200px">
                <b-form-group @submit.stop.prevent label="Username">
                  <b-form-input
                    @submit.stop.prevent
                    size="sm"
                    placeholder="Search"
                    v-model.trim="filterUsername"
                  ></b-form-input>
                </b-form-group>
                <b-button
                  variant="success"
                  size="sm"
                  @click="setFilter('username', filterUsername)"
                  >Filter</b-button
                >
                <b-button
                  variant="light"
                  size="sm"
                  class="ml-2"
                  @click="
                    setFilter('username', '')
                    filterUsername = ''
                  "
                  >Clear</b-button
                >
              </b-dropdown-form>
            </b-dropdown>

            <div style="width:160px"></div>
          </template>

          <template v-slot:head(text)="data">
            <b-dropdown
              ref="filter_text"
              id="filter-text"
              dropdown
              right
              variant="text"
              :toggle-class="
                filter.text !== ''
                  ? 'font-weight-bolder p-0 text-center text-success'
                  : 'font-weight-bolder p-0 text-center'
              "
            >
              <template #button-content> {{ data.label }}</template>
              <b-dropdown-form style="min-width:200px">
                <b-form-group @submit.stop.prevent label="Text">
                  <b-form-input
                    @submit.stop.prevent
                    size="sm"
                    placeholder="Search"
                    v-model.trim="filterText"
                  ></b-form-input>
                </b-form-group>
                <b-button
                  variant="success"
                  size="sm"
                  @click="setFilter('text', filterText)"
                  >Filter</b-button
                >
                <b-button
                  variant="light"
                  size="sm"
                  class="ml-2"
                  @click="
                    setFilter('text', '')
                    filterText = ''
                  "
                  >Clear</b-button
                >
              </b-dropdown-form>
            </b-dropdown>

            <div style="width:160px"></div>
          </template>

          <template v-slot:head(link)="data">
            <b-dropdown
              ref="filter_link"
              id="filter-link"
              dropdown
              right
              variant="text"
              :toggle-class="
                filter.link !== ''
                  ? 'font-weight-bolder p-0 text-center text-success'
                  : 'font-weight-bolder p-0 text-center'
              "
            >
              <template #button-content> {{ data.label }}</template>
              <b-dropdown-form style="min-width:200px">
                <b-form-group @submit.stop.prevent label="Link">
                  <b-form-input
                    @submit.stop.prevent
                    size="sm"
                    placeholder="Search"
                    v-model.trim="filterLink"
                  ></b-form-input>
                </b-form-group>
                <b-button
                  variant="success"
                  size="sm"
                  @click="setFilter('link', filterLink)"
                  >Filter</b-button
                >
                <b-button
                  variant="light"
                  size="sm"
                  class="ml-2"
                  @click="
                    setFilter('link', '')
                    filterLink = ''
                  "
                  >Clear</b-button
                >
              </b-dropdown-form>
            </b-dropdown>

            <div style="width:160px"></div>
          </template>

          <template v-slot:head(intention)="data">
            <b-dropdown
              id="filter_intention"
              ref="filter_intention"
              dropdown
              right
              variant="text"
              :toggle-class="
                filter.intention !== 'all'
                  ? 'font-weight-bolder p-0 text-center text-success'
                  : 'font-weight-bolder p-0 text-center'
              "
            >
              <template #button-content> {{ data.label }}</template>
              <b-dropdown-item
                href="#"
                :active="filter.intention === 'all'"
                @click="setFilter('intention', 'all')"
                >All</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                @click="setFilter('intention', intention)"
                v-for="intention in intentionOpts"
                :key="intention"
                :active="filter.intention === intention"
                >{{ intention }}</b-dropdown-item
              >
            </b-dropdown>

            <div style="width:120px"></div>
          </template>

          <template v-slot:head(created_at)="data">
            <b-dropdown
              ref="filter_created_at"
              id="filter-created_at"
              dropdown
              right
              variant="text"
              :toggle-class="
                filter.created_at !== ''
                  ? 'font-weight-bolder p-0 text-center text-success'
                  : 'font-weight-bolder p-0 text-center'
              "
              @hide="handleHide($event, 'filter_created_at')"
              @hidden="canClose = false"
            >
              <template #button-content> {{ data.label }}</template>
              <b-dropdown-form style="min-width:200px">
                <span
                  class="position-absolute pr-3 pt-1 font-weight-boldest text-muted font-size-h6 cursor-pointer"
                  style="top:0;right:0"
                  @click="
                    canClose = true
                    $refs['filter_created_at'].hide(true)
                  "
                  >×</span
                >
                <b-form-group @submit.stop.prevent label="Created At">
                  <b-form-datepicker
                    placeholder="Choose a date"
                    size="sm"
                    v-model="filterCreatedAt"
                    :max="new Date()"
                    locale="en"
                  ></b-form-datepicker>
                </b-form-group>
                <b-button
                  variant="success"
                  size="sm"
                  @click="setFilter('created_at', filterCreatedAt)"
                  >Filter</b-button
                >
                <b-button
                  variant="light"
                  size="sm"
                  class="ml-2"
                  @click="
                    setFilter('created_at', '')
                    filterCreatedAt = ''
                  "
                  >Clear</b-button
                >
              </b-dropdown-form>
            </b-dropdown>

            <div style="width:160px"></div>
          </template>

          <template v-slot:head(updated_at)="data">
            <b-dropdown
              ref="filter_updated_at"
              id="filter-updated_at"
              dropdown
              right
              variant="text"
              :toggle-class="
                filter.updated_at !== ''
                  ? 'font-weight-bolder p-0 text-center text-success'
                  : 'font-weight-bolder p-0 text-center'
              "
              @hide="handleHide($event, 'filter_updated_at')"
              @hidden="canClose = false"
            >
              <template #button-content> {{ data.label }}</template>
              <b-dropdown-form style="min-width:200px">
                <span
                  class="position-absolute pr-3 pt-1 font-weight-boldest text-muted font-size-h6 cursor-pointer"
                  style="top:0;right:0"
                  @click="
                    canClose = true
                    $refs['filter_updated_at'].hide(true)
                  "
                  >×</span
                >
                <b-form-group @submit.stop.prevent label="Updated At">
                  <b-form-datepicker
                    placeholder="Choose a date"
                    size="sm"
                    v-model="filterUpdatedAt"
                    :max="new Date()"
                    locale="en"
                  ></b-form-datepicker>
                </b-form-group>
                <b-button
                  variant="success"
                  size="sm"
                  @click="setFilter('updated_at', filterUpdatedAt)"
                  >Filter</b-button
                >
                <b-button
                  variant="light"
                  size="sm"
                  class="ml-2"
                  @click="
                    setFilter('updated_at', '')
                    filterUpdatedAt = ''
                  "
                  >Clear</b-button
                >
              </b-dropdown-form>
            </b-dropdown>

            <div style="width:160px"></div>
          </template>
          <template v-slot:empty>
            <center>No data</center>
          </template>

          <template v-slot:cell(checkbox)="row">
            <b-form-checkbox
              size="lg"
              :value="row.item.linkId"
              v-model="selectedLinkIds"
              :disabled="!row.item.can_copy"
            ></b-form-checkbox>
          </template>

          <template v-slot:cell(no)="row">
            {{ iterationLink + row.index + 1 }}
          </template>

          <template v-slot:cell(text)="row">
            <div style="width:max-content;max-width:400px;min-width:300px">
              <b-form-textarea
                type="text"
                size="lg"
                :value="decodeHTML(row.item.text)"
                readonly
                rows="5"
              ></b-form-textarea>
            </div>
          </template>

          <template v-slot:cell(link)="row">
            <a :href="row.item.link" target="_blank" rel="noopener noreferrer"
              ><i class="flaticon2-graphic-design"></i
            ></a>
          </template>

          <template v-slot:cell(isActive)="row">
            <center>
              <badge-status :active="row.item.isActive"></badge-status>
            </center>
          </template>

          <template v-slot:cell(isPersonal)="row">
            <center>
              <span v-if="row.item.isPersonal">Personal</span>
              <span v-else>Non Personal</span>
            </center>
          </template>

          <template v-slot:cell(recentEngagement)="row">
            <div>
              <i class="mr-2 flaticon-like"></i
              >{{ row.item.recentEngagement.like }}
            </div>

            <div>
              <i class="mr-2 flaticon-comment"></i
              >{{ row.item.recentEngagement.comment }}
            </div>
            <div>
              <i class="mr-2 flaticon-share"></i
              >{{ row.item.recentEngagement.retweet }}
            </div>
            <div>
              <i class="mr-2 flaticon-medical"></i
              >{{ row.item.recentEngagement.view }}
            </div>
            <div class="mt-3">
              <i class="mr-2 flaticon-open-box"></i
              >{{ row.item.totalEngagement }}
            </div>
          </template>

          <template v-slot:cell(actions)="row">
            <div style="width:150px">
              <button-edit
                v-b-modal.topic-link-edit
                @click="selectedTopicLink = row.item"
              >
                <i class="flaticon2-edit icon-md"></i>
              </button-edit>
              <button-delete
                v-b-modal.delete-link
                @click="selectedTopicLink = row.item"
              >
                <i class="flaticon2-trash icon-md"></i>
              </button-delete>

              <b-button
                tag="a"
                size="sm"
                variant="light"
                class="btn-icon mx-1"
                @click="selectedTopicLink = row.item"
                :disabled="row.item.isSend"
                :to="
                  `line-incidents/?cusId=${shareData(
                    'topicBrandSelectId'
                  )}&link=${row.item.link}`
                "
                target="_blank"
              >
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg src="media/svg/logos/line-logo.svg"></inline-svg>
                </span>
              </b-button>

              <b-button
                v-b-modal.force-update
                @click="selectedTopicLink = row.item"
                :disabled="!row.item.can_force_update"
                >Force Update</b-button
              >
            </div>
          </template>
        </b-table>
        <div class="d-flex flex-row justify-content-between align-items-center">
          <span v-if="pagingTopicLink"
            >{{ pagingTopicLink.totalRows | number_format }} records</span
          >
          <b-pagination
            v-if="pagingTopicLink.numberOfPages"
            v-model="pagingTopicLink.currentPage"
            :total-rows="pagingTopicLink.totalRows"
            align="right"
            :number-of-pages="pagingTopicLink.numberOfPages"
            :per-page="pagingTopicLink.perPage"
            use-router
            @change="changeTopicLink"
          ></b-pagination>
        </div>
      </template>
    </KTCard>

    <confirm-delete
      modal-id="delete-link"
      :remove-fn="removeLink"
      @ok="changeTopicLink(1)"
    ></confirm-delete>

    <topic-link-create
      :selected-topic="selectedTopic"
      v-if="selectedTopic"
      @success="changeTopicLink(1)"
    ></topic-link-create>

    <topic-link-edit
      :selected-topic="selectedTopic"
      v-if="selectedTopic && selectedTopicLink"
      :topic-link-info="selectedTopicLink"
      @hidden="resetTopicLink"
      @success="changeTopicLink()"
    ></topic-link-edit>

    <Summary
      :selected-topic="selectedTopic"
      v-if="selectedTopic && actionReady"
      @hidden="actionReady = !$event"
    ></Summary>

    <ForceUpdateEngagement
      v-if="selectedTopicLink"
      :link-info="selectedTopicLink"
      @hidden="resetTopicLink"
      @success="changeTopicLink()"
    ></ForceUpdateEngagement>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import { SET_COPY_INFO } from "@/core/services/store/topic.module"
import { TopicLinksService } from "@/core/services"
import KTCard from "@/view/content/Card.vue"

import moment from "moment"
import TopicLinkDropDown from "@/view/content/custom/TopicLinkDropDown.vue"

import _ from "lodash"
import TopicLinkCreate from "./TopicLinkCreate.vue"
import TopicLinkEdit from "./TopicLinkEdit.vue"
import Summary from "./Summary"
import ForceUpdateEngagement from "./ForceUpdateEngagement"
import { mapGetters } from "vuex"
import HtmlEntities from "../../../core/plugins/html-entities"
const initFilter = {
  isPersonal: "all",
  source: "all",
  intention: "all",
  username: "",
  link: "",
  text: "",
  created_at: "",
  updated_at: ""
}
export default {
  name: "TopicLink",
  props: {
    selectedTopic: {
      type: Object,
      required: true
    }
  },
  components: {
    KTCard,
    TopicLinkDropDown,
    TopicLinkCreate,
    TopicLinkEdit,
    Summary,
    ForceUpdateEngagement
  },
  data() {
    return {
      sources: [
        "facebook",
        "twitter",
        "pantip",
        "youtube",
        "website",
        "instagram",
        "tiktok"
      ],
      fields: [
        { value: "all", text: "All Field" },

        { value: "username", text: "Username" },
        { value: "link", text: "Link" },
        { value: "source", text: "Source" },
        { value: "text", text: "Text" },
        { value: "isPersonal", text: "Personal" },
        { value: "intention", text: "Intention" }
      ],
      selectedLinkIds: [],
      selectedDuplicateTopic: null,
      actionReady: false,
      isTopicLinkLoaded: false,
      selectedTopicLink: null,
      pagingTopicLink: {
        perPage: 10,
        currentPage: 1,
        totalRows: 0,
        numberOfPages: 0
      },

      topicLinkDatas: [],
      topicLinkFields: [
        {
          key: "checkbox",
          label: "",
          class: "text-center"
        },
        {
          key: "no",
          label: "#",
          class: "text-center"
        },

        {
          key: "isActive",
          label: "Status"
        },
        {
          key: "isPersonal",
          label: "Personal Post"
        },
        {
          key: "source",
          label: "Source"
        },
        { key: "username", label: "Username" },
        {
          key: "text",
          label: "Text"
        },
        {
          key: "recentEngagement",
          label: "Engagement"
        },
        {
          key: "link",
          label: "Link"
        },
        {
          key: "intention",
          label: "Intention"
        },
        {
          key: "created_at",
          label: "Created At"
        },
        {
          key: "updated_at",
          label: "Updated At"
        },
        {
          key: "actions",
          label: "Action",
          class: "text-center"
        }
      ],
      topicLinkSearch: "",
      hourlyUpdatedCount: null,
      filter: Object.assign({}, initFilter),
      intentionOpts: [],
      filterUsername: "",
      filterLink: "",
      filterText: "",
      filterCreatedAt: "",
      filterUpdatedAt: "",
      canClose: false
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Topic" }])
    this.pagingTopicLink.currentPage = Number(this.$route.query.page) || 1
    this.getTopicLinks()
    this.intentionOpts = this.selectedTopic.intention
  },

  computed: {
    ...mapGetters(["shareData"]),
    iteration() {
      return this.paging.perPage * (this.paging.currentPage - 1)
    },
    iterationLink() {
      return (
        this.pagingTopicLink.perPage * (this.pagingTopicLink.currentPage - 1)
      )
    }
  },
  watch: {
    selectedLinkIds() {
      this.$store.dispatch(SET_COPY_INFO, this.selectedLinkIds)
    },
    topicLinkSearch: _.debounce(function() {
      this.pagingTopicLink.currentPage = 1
      this.filter = Object.assign({}, initFilter)
      this.getTopicLinks()
    }, 500),

    "pagingTopicLink.perPage"() {
      this.pagingTopicLink.currentPage = 1
      this.getTopicLinks()
    }
  },
  methods: {
    handleHide(e, target) {
      if (!this.canClose) {
        e.preventDefault()
      } else {
        this.$refs[target].hide()
      }
    },

    setFilter(key, value) {
      this.filter[key] = value
      this.canClose = true
      this.$refs[`filter_${key}`].hide(true)

      this.pagingTopicLink.currentPage = 1
      this.getTopicLinks()
    },
    decodeHTML(str) {
      return HtmlEntities.decode(str)
    },
    resetTopicLink() {
      this.selectedTopicLink = null
    },
    changeTopicLink(page) {
      this.pagingTopicLink.currentPage = page
        ? page
        : this.pagingTopicLink.currentPage
      this.getTopicLinks()
    },
    getTopicLinks() {
      let criteria = []
      let qs = ""
      for (let key in this.filter) {
        if (this.filter[key] !== "all" && this.filter[key] !== "") {
          criteria.push(`${key}:${this.filter[key]}`)
        }
      }

      if (this.topicLinkSearch) {
        criteria.push(`all:${this.topicLinkSearch}`)
      }

      qs = criteria.join(" AND ")

      let q = encodeURIComponent(qs)

      this.isTopicLinkLoaded = false
      this.hourlyUpdatedCount = null
      this.getHourlyUpdatedCount()
      TopicLinksService.get({
        topicId: this.selectedTopic.topicId,
        offset:
          this.pagingTopicLink.perPage * (this.pagingTopicLink.currentPage - 1),
        limit: this.pagingTopicLink.perPage,
        q: q
      }).then(topicLinks => {
        this.pagingTopicLink.totalRows = topicLinks.total
        this.pagingTopicLink.numberOfPages = Math.ceil(
          this.pagingTopicLink.totalRows / this.pagingTopicLink.perPage
        )
        let topicLinkDatas = topicLinks.results || []
        this.topicLinkDatas = topicLinkDatas.map(v => {
          return {
            ...v,
            created_at: moment(v.created_at).format("DD/MM/YYYY HH:mm"),
            updated_at: moment(v.updated_at).format("DD/MM/YYYY HH:mm"),
            can_force_update: moment(v.updated_at).isAfter(
              moment().startOf("hours")
            ),
            can_copy: moment(v.updated_at).isAfter(moment().startOf("hours"))
          }
        })
        this.isTopicLinkLoaded = true
      })
    },
    removeLink() {
      return TopicLinksService.delete(this.selectedTopicLink._id)
    },
    getHourlyUpdatedCount() {
      TopicLinksService.getHourlyUpdatedCount(this.selectedTopic.topicId).then(
        count => {
          this.hourlyUpdatedCount = count
        }
      )
    }
  }
}
</script>
