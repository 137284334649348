import { render, staticRenderFns } from "./TopicLinkEdit.vue?vue&type=template&id=01ad4142"
import script from "./TopicLinkEdit.vue?vue&type=script&lang=js"
export * from "./TopicLinkEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports