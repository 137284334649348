<template>
  <b-modal
    size="md"
    id="topic-link-edit"
    title="Edit Topic Link"
    ok-title="Save"
    @ok="submit"
    @hidden="hidden"
    :ok-disabled="isProcessing"
  >
    <b-form autocomplete="off">
      <b-form-group>
        <label for="link">Link <span class="text-danger">*</span></label>
        <div class="input-icon input-icon-right">
          <b-form-input
            size="sm"
            v-model="form.link"
            id="link"
            readonly
          ></b-form-input>
          <span v-if="resolveState === 'resolving'"
            ><i class="spinner spinner-info mr-5 "></i
          ></span>
          <span v-if="resolveState === 'success'"
            ><i class="flaticon2-check-mark text-success"></i
          ></span>
          <span v-if="resolveState === 'exist'"
            ><i class="text-danger flaticon-exclamation-1"></i
          ></span>
        </div>
      </b-form-group>

      <b-form-group>
        <div class="d-flex flex-row">
          <b-form-checkbox
            id="isPersonal"
            v-model="form.isPersonal"
            :value="true"
            :unchecked-value="false"
          >
            Personal Post
          </b-form-checkbox>

          <b-form-checkbox
            class="ml-3"
            id="facebookGroup"
            v-model="form.isFacebookGroup"
            :value="true"
            :unchecked-value="false"
          >
            Facebook Group
          </b-form-checkbox>
        </div>
      </b-form-group>

      <b-form-group>
        <b-row>
          <b-col sm="4">
            <label for="source"
              >Source <span class="text-danger">*</span></label
            >

            <b-select
              :options="sources"
              size="sm"
              v-model="form.source"
              id="source"
            ></b-select>
          </b-col>
          <b-col sm="4">
            <label for="username"
              >Username <span class="text-danger">*</span></label
            >

            <b-form-input
              size="sm"
              type="text"
              v-model="form.username"
              id="username"
            ></b-form-input>
          </b-col>
          <b-col sm="4">
            <label for="pageName">Page Name</label>
            <b-form-input
              size="sm"
              type="text"
              v-model="form.pageName"
              id="pageName"
            ></b-form-input>
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group>
        <b-row>
          <b-col
            ><label for="postDay"
              >Post Date <span class="text-danger">*</span></label
            >
            <div>
              <el-date-picker
                v-model="form.postDate"
                type="datetime"
                size="sm"
                placeholder="Select date and time"
                format="dd/MM/yyyy HH:mm"
                value-format="timestamp"
                :clearable="false"
              >
              </el-date-picker></div
          ></b-col>
          <b-col>
            <label for="fanCount">Fan Count</label>
            <b-form-input
              size="sm"
              type="text"
              v-model="form.fanCount"
              id="fanCount"
            ></b-form-input>
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group>
        <b-row>
          <b-col sm="3">
            <label><i class="mr-1 flaticon-like"></i>Like</label>
            <b-form-input
              size="sm"
              type="number"
              min="0"
              v-model="form.recentEngagement.like"
              readonly
            ></b-form-input>
          </b-col>
          <b-col sm="3">
            <label><i class="mr-1 flaticon-comment"></i>Comment</label>
            <b-form-input
              size="sm"
              type="number"
              min="0"
              v-model="form.recentEngagement.comment"
              readonly
            ></b-form-input>
          </b-col>
          <b-col sm="3">
            <label><i class="mr-1 flaticon-share"></i>Share/Retweet</label>
            <b-form-input
              size="sm"
              type="number"
              min="0"
              v-model="form.recentEngagement.retweet"
              readonly
            ></b-form-input>
          </b-col>
          <b-col sm="3">
            <label><i class="mr-1 flaticon-eye"></i>View</label>
            <b-form-input
              size="sm"
              type="number"
              min="0"
              v-model="form.recentEngagement.view"
              readonly
            ></b-form-input>
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group>
        <label for="text">Text <span class="text-danger">*</span></label>

        <b-form-textarea
          type="text"
          size="lg"
          v-model="form.text"
          id="text"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group>
        <label for="intention">Intention</label>

        <b-select
          :options="intentionOpts"
          v-model="form.intention"
          id="intention"
        ></b-select>
      </b-form-group>

      <b-form-group>
        <label for="relatedTopics">Related Topics</label>

        <multiselect
          v-model="form.relatedTopic"
          :multiple="true"
          :options="topics"
          :searchable="true"
          :close-on-select="false"
          :show-labels="true"
          :hide-selected="false"
          placeholder="Search topic"
          :limit="3"
          :options-limit="300"
          track-by="topicId"
          label="name"
          id="relatedTopics"
        ></multiselect>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import { required } from "vuelidate/lib/validators"
import { LinkInfosService, UtilsService } from "@/core/services"
import moment from "moment"
import _ from "lodash"
export default {
  name: "TopicLinkEdit",
  props: {
    selectedTopic: {
      type: Object,
      required: true
    },
    topicLinkInfo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      resolveState: "ready", // ready,resolving,success,exist
      sources: [
        "facebook",
        "twitter",
        "pantip",
        "youtube",
        "website",
        "instagram",
        "tiktok"
      ],
      form: {
        link: null,
        source: null,
        username: null,
        text: null,
        postDay: null,
        postTime: null,
        recentEngagement: {
          like: null,
          comment: null,
          retweet: null,
          view: null
        },

        isPersonal: false,
        isActive: true,
        isFirst: true,
        isCountEngagement: true,
        isForceDOMUpdate: false,
        note: null,
        intention: null,
        relatedTopic: [],
        postId: null,
        fanCount: null,
        isFacebookGroup: false,
        pageName: null
      },
      intentionOpts: [],
      topics: [
        {
          topicId: "xxx",
          name: "testtt"
        }
      ],
      isProcessing: false
    }
  },
  validations: {
    form: {
      link: { required },
      source: { required },
      username: { required },
      text: { required },
      postDate: { required },
      isPersonal: { required },
      recentEngagement: { required }
    }
  },
  mounted() {
    UtilsService.getIntentions().then(response => {
      this.intentionOpts = response.intentions
      let data = _.cloneDeep(this.topicLinkInfo)
      data.postDate = moment(data.postDate).valueOf()
      this.form = data
    })
  },
  methods: {
    hidden() {
      this.resetForm()
      this.$emit("hidden")
    },
    resetForm() {
      this.resolveState = "ready"
      this.form = Object.assign(
        {},
        {
          link: null,
          source: null,
          username: null,
          text: null,
          postDay: null,
          postTime: null,
          isPersonal: false,
          recentEngagement: {
            like: null,
            comment: null,
            retweet: null,
            view: null
          },

          isActive: true,
          isFirst: true,
          isCountEngagement: true,
          isForceDOMUpdate: false,
          note: null,
          intention: this.intentionOpts[0],
          relatedTopic: [],
          fanCount: 0,
          postId: null,
          isFacebookGroup: false,
          pageName: null
        }
      )
    },
    async submit(e) {
      e.preventDefault()
      this.isProcessing = true
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.isProcessing = false
        return
      }
      let data = Object.assign({}, this.form)
      data.linkId = this.topicLinkInfo.linkId

      let response = await LinkInfosService.update(data)
      if (!response.error) {
        this.$emit("success")
        this.$root.$bvToast.toast("Update topic link success", {
          title: `Success`,
          variant: "success",
          solid: true
        })
        this.$bvModal.hide("topic-link-edit")
      }
      this.isProcessing = false
    }
  }
}
</script>

<style></style>
