<template>
  <b-dropdown
    size="sm"
    variant="button"
    toggle-class="custom-v-dropdown btn btn-light btn-sm btn-icon"
    no-caret
    right
    no-flip
  >
    <template v-slot:button-content>
      <i class="ki ki-bold-more-hor text-primary"></i>
    </template>
    <!--begin::Navigation-->
    <div class="navi navi-hover min-w-md-250px">
      <b-dropdown-text tag="div" class="navi-item">
        <a href="#" @click.prevent="ready" class="navi-link" v-b-modal.summary>
          <span class="navi-icon">
            <i class="flaticon2-writing"></i>
          </span>
          <span class="navi-text">Summary Engagement</span>
        </a>
      </b-dropdown-text>

      <b-dropdown-text tag="div" class="navi-item">
        <a
          href="#"
          @click.prevent="ready"
          class="navi-link"
          v-b-toggle.export-section
        >
          <span class="navi-icon">
            <i class="flaticon2-writing"></i>
          </span>
          <span class="navi-text">Export</span>
          <span class="when-closed"><i class="fa fa-chevron-down"></i></span>
        </a>
      </b-dropdown-text>

      <b-collapse id="export-section" style="margin-left:20px">
        <b-dropdown-text tag="div" class="navi-item">
          <a
            href="#"
            @click.prevent
            class="navi-link"
            @click="downloadEngagement"
          >
            <span class="navi-icon">
              <i class="flaticon2-file-2"></i>
            </span>
            <span class="navi-text">Export Current Engagement</span>
          </a>
        </b-dropdown-text>

        <b-dropdown-text tag="div" class="navi-item">
          <a
            href="#"
            @click.prevent
            class="navi-link"
            v-b-modal.export-range-engagement-topic
          >
            <span class="navi-icon">
              <i class="flaticon2-calendar-9"></i>
            </span>
            <span class="navi-text">Export Engagement</span>
          </a>
        </b-dropdown-text>

        <b-dropdown-text tag="div" class="navi-item">
          <a
            href="#"
            @click.prevent
            class="navi-link"
            v-b-modal.export-range-engagement-topic-with-dom
          >
            <span class="navi-icon">
              <i class="flaticon-calendar-with-a-clock-time-tools"></i>
            </span>
            <span class="navi-text">Export Engagement With DOM</span>
          </a>
        </b-dropdown-text>
      </b-collapse>

      <b-dropdown-text tag="div" class="navi-item">
        <a
          href="#"
          @click.prevent
          class="navi-link"
          v-b-modal.copy-links-to-topic
        >
          <span class="navi-icon">
            <i class="flaticon2-copy"></i>
          </span>
          <span class="navi-text">Copy Link to Topic</span>
        </a>
      </b-dropdown-text>
    </div>
    <!--end::Navigation-->
    <ExportRangeEngagement :topic-id="topicId"></ExportRangeEngagement>
    <CopyLinkModal :topic-id="topicId"></CopyLinkModal>
    <ExportRangeEngagementWithDOM
      :topic-id="topicId"
    ></ExportRangeEngagementWithDOM>
  </b-dropdown>
</template>

<script>
import { TopicLinksService } from "@/core/services";
import FileSaver from "file-saver";
import ExportRangeEngagement from "@/view/pages/topic/ExportRangeEngagement.vue";
import ExportRangeEngagementWithDOM from "@/view/pages/topic/ExportRangeEngagementWithDOM.vue";
import CopyLinkModal from "@/view/content/custom/CopyLinkModal.vue";

export default {
  name: "TopicLinkDropDown",
  components: {
    ExportRangeEngagement,
    CopyLinkModal,
    ExportRangeEngagementWithDOM
  },
  props: {
    topicId: {
      type: String,
      required: true
    },
    actionReady: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    ready() {
      this.$emit("update:action-ready", true);
    },
    async downloadEngagement() {
      let blob = await TopicLinksService.downloadEngagement({
        topicId: this.topicId
      });
      FileSaver.saveAs(blob.blob, decodeURIComponent(blob.filename));
    }
  }
};
</script>

<style>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  transform: rotate(180deg);
  transition: transform 0.3s;
}

.collapsed > .when-closed,
.not-collapsed > .when-open {
  transition: transform 0.3s;
}
</style>
