<template>
  <b-modal
    id="export-range-engagement-topic-with-dom"
    ref="modal"
    title="Export engagement by date range with DOM"
    ok-variant="primary"
  >
    <b-row>
      <b-col md="6">
        <b-form-group>
          <multiselect
            :options="accounts"
            v-model="selectedAccount"
            placeholder="Account"
          >
          </multiselect>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group>
          <!-- <multiselect
          :options="lines"
          multiple
          v-model="form.reportWay.lines"
          track-by="value"
          label="text"
          id="reportWay"
        ></multiselect> -->

          <multiselect
            :options="categories0"
            v-model="cat0"
            track-by="category"
            label="category"
            multiple
            placeholder="Select Category 0"
          >
          </multiselect>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="6">
        <b-form-group>
          <multiselect
            :options="categories1"
            v-model="cat1"
            track-by="category"
            label="category"
            multiple
            placeholder="Select Category 1"
          >
          </multiselect>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group>
          <multiselect
            :options="categories2"
            v-model="cat2"
            track-by="category"
            label="category"
            multiple
            placeholder="Select Category 2"
          >
          </multiselect>
        </b-form-group> </b-col
    ></b-row>

    <b-row>
      <b-col md="6">
        <b-form-group>
          <multiselect
            :options="categories3"
            v-model="cat3"
            track-by="category"
            label="category"
            multiple
            placeholder="Select Category 3"
          >
          </multiselect>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group>
          <multiselect
            :options="categories4"
            v-model="cat4"
            track-by="category"
            label="category"
            multiple
            placeholder="Select Category 4"
          >
          </multiselect>
        </b-form-group> </b-col
    ></b-row>
    <el-date-picker
      :editable="false"
      :clearable="false"
      class="w-100"
      v-model="dateRange"
      type="datetimerange"
      align="left"
      size="small"
      start-placeholder="Start Date"
      end-placeholder="End Date"
      format="dd/MM/yyyy HH:mm"
      value-format="timestamp"
    >
    </el-date-picker>
    <template #modal-footer>
      <div class="text-right">
        <b-button
          variant="primary"
          ref="export_btn"
          @click="downloadRangeEngagementWithDOM"
          >Export</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import { TopicLinksService, UtilsService } from "@/core/services";
import moment from "moment";
import FileSaver from "file-saver";
export default {
  name: "ExportRangeEngagementWithDOM",
  props: {
    topicId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dateRange: [
        moment()
          .startOf("hour")
          .valueOf(),
        moment()
          .endOf("hour")
          .valueOf()
      ],

      accounts: [],
      selectedAccount: null,

      categories0: [],
      categories1: [],
      categories2: [],
      categories3: [],
      categories4: [],

      cat0: [],
      cat1: [],
      cat2: [],
      cat3: [],
      cat4: []
    };
  },
  mounted() {
    this.getDOMAccount();
  },
  watch: {
    selectedAccount() {
      this.getDOMCategory();
    }
  },
  methods: {
    async getDOMAccount() {
      this.accounts = [];
      let response = await UtilsService.getDOMAccount();
      if (!response.error) {
        this.accounts = response.map(v => v.id);
      }
    },
    async getDOMCategory() {
      this.categories = [];
      let response = await UtilsService.getDOMCategory(this.selectedAccount);
      if (!response.error) {
        this.categories0 = response
          .filter(v => v.level === 0)[0]
          .categories.filter(v => v.visible);
        this.categories1 = response
          .filter(v => v.level === 1)[0]
          .categories.filter(v => v.visible);
        this.categories2 = response
          .filter(v => v.level === 2)[0]
          .categories.filter(v => v.visible);
        this.categories3 = response
          .filter(v => v.level === 3)[0]
          .categories.filter(v => v.visible);
        this.categories4 = response
          .filter(v => v.level === 4)[0]
          .categories.filter(v => v.visible);
      }
    },
    async downloadRangeEngagementWithDOM(e) {
      e.preventDefault();
      this.$refs["export_btn"].classList.add(
        "spinner",
        "spinner-light",
        "spinner-right",
        "disabled"
      );
      let category = [];
      if (this.cat0.length) {
        category.push({
          category: this.cat0.map(v => v.category),
          level: 0
        });
      }
      if (this.cat1.length) {
        category.push({
          category: this.cat1.map(v => v.category),
          level: 1
        });
      }
      if (this.cat2.length) {
        category.push({
          category: this.cat2.map(v => v.category),
          level: 2
        });
      }
      if (this.cat3.length) {
        category.push({
          category: this.cat3.map(v => v.category),
          level: 3
        });
      }
      if (this.cat4.length) {
        category.push({
          category: this.cat4.map(v => v.category),
          level: 4
        });
      }
      let blob = await TopicLinksService.downloadRangeEngagementWithDOM({
        topicId: this.topicId,
        from: moment(this.dateRange[0])
          .startOf("hour")
          .valueOf(),
        to: moment(this.dateRange[1])
          .startOf("hour")
          .valueOf(),
        account: this.selectedAccount,
        category: category
      });
      FileSaver.saveAs(blob.blob, decodeURIComponent(blob.filename));
      this.$refs["export_btn"].classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right",
        "disabled"
      );
    }
  }
};
</script>

<style></style>
