<template>
  <b-modal
    size="md"
    id="topic-edit"
    title="Edit Topic"
    ok-title="Save"
    @ok="submit"
    :ok-disabled="isProcessing"
  >
    <div class="form-group row">
      <div class="col-sm-3">
        <label for="name">Topic Name <span class="text-danger">*</span></label>
      </div>
      <div class="col-sm-9">
        <b-form-input v-model="form.name" id="name"></b-form-input>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-3">
        <label for="name">Topic Brand</label>
      </div>
      <div class="col-sm-9">
        <b-form-input v-model="form.brand" id="brand"></b-form-input>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-3">
        <label for="isActive">Status <span class="text-danger">*</span></label>
      </div>
      <div class="col-sm-9">
        <b-form-checkbox
          switch
          size="lg"
          v-model="form.isActive"
          id="isActive"
        ></b-form-checkbox>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-3"><label for="intention">Intention</label></div>
      <div class="col-sm-9">
        <b-form-textarea
          @change="onChangeIntention"
          placeholder="Enter something..."
          rows="3"
          v-model="intentionText"
          id="intention"
        ></b-form-textarea>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-3">
        <label for="relatedTopics">Related Topics</label>
      </div>
      <div class="col-sm-9">
        <multiselect
          v-model="form.relatedTopic"
          :multiple="true"
          :options="topics"
          :searchable="true"
          :close-on-select="false"
          :show-labels="true"
          :hide-selected="false"
          placeholder="Search topic"
          :limit="3"
          :options-limit="300"
          track-by="topicId"
          label="name"
          id="relatedTopics"
        ></multiselect>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-3">
        <label for="relatedTopics">Run Hours</label>
      </div>
      <div class="col-sm-9">
        <b-form-radio-group
          v-model="form.frequencyHour"
          :options="runHoursOptions"
          name="radio-options"
        ></b-form-radio-group>
        <div>
          <b-form-group>
            <div class="text-muted">Ex. 1,2,3,4,5,6,7,8</div>
            <b-form-input
              type="text"
              placeholder="Ex. 1,2,3,4,5,6,7,8"
              v-model="form.runHoursText"
              :disabled="form.frequencyHour !== 'custom'"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { TopicService } from "@/core/services";
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "TopicEdit",
  props: {
    selectedBrand: {
      type: String,
      required: true
    },
    selectedTopic: {
      type: Object,
      default: () => {
        return {};
      },
      required: true
    }
  },
  data() {
    return {
      form: {
        name: null,
        brand: null,
        isActive: false,
        intention: [],
        relatedTopic: [],
        runHoursText: "",
        runHours: []
      },
      intentionText: "",
      isProcessing: false,
      topics: [],
      runHoursOptions: [
        { text: "Every hour", value: "1" },
        { text: "Every 3 hour", value: "3" },
        { text: "Custom", value: "custom" }
      ]
    };
  },
  validations: {
    form: {
      name: { required },
      isActive: { required }
    }
  },
  mounted() {
    this.topics = this.topicData("topics").filter(
      v => v.topicId !== this.selectedTopic.topicId
    );
    let {
      name,
      brand,
      isActive,
      intention,
      relatedTopic,
      frequencyHour,
      runHours
    } = _.cloneDeep(this.selectedTopic);

    this.form = {
      name,
      brand,
      isActive,
      intention,
      relatedTopic: this.topicData("topics").filter(v =>
        relatedTopic ? relatedTopic.includes(v.topicId) : false
      ),
      frequencyHour: frequencyHour ? frequencyHour : "1",
      runHours: runHours ? runHours : [],
      runHoursText: runHours ? runHours.join(",") : "1"
    };

    this.intentionText = this.form.intention.join("\n");
  },
  computed: {
    ...mapGetters(["topicData"])
  },
  watch: {
    "form.frequencyHour": function(newFrequencyHour) {
      if (newFrequencyHour && newFrequencyHour !== "custom") {
        let hours = [];
        for (let index = 8; index < 24; index += Number(newFrequencyHour)) {
          // let isInHour = index % newFrequencyHour === 0;
          // if (isInHour) {
          hours.push(index);
          // }
        }
        this.form.runHours = hours;
      } else {
        if (this.selectedTopic.runHours.length) {
          this.form.runHours = this.selectedTopic.runHours;
        } else {
          this.form.runHours = [];
        }
      }
      this.form.runHoursText = this.form.runHours.join(",");
    },
    "form.runHoursText": function(newRunHour) {
      if (typeof newRunHour === "string") {
        this.form.runHours = newRunHour.split(",", -1);
      }
    }
  },

  methods: {
    resetForm() {
      this.form = Object.assign(
        {},
        {
          name: null,
          brand: null,
          isActive: null,
          intention: [],
          relatedTopic: [],
          frequencyHour: "1",
          runHours: []
        }
      );
      this.intentionText = "";
    },
    async submit(e) {
      e.preventDefault();
      this.isProcessing = true;
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.isProcessing = false;
        return;
      }
      let data = Object.assign({}, this.form);
      data.relatedTopic = data.relatedTopic.map(v => v.topicId);
      data.cusId = this.selectedBrand;
      data.topicId = this.selectedTopic.topicId;

      let response = await TopicService.update(data);
      if (!response.error) {
        this.$emit("success");
        this.$root.$bvToast.toast("Update topic success", {
          title: `Success`,
          variant: "success",
          solid: true
        });
        this.resetForm();
      }

      this.isProcessing = false;
    },
    onChangeIntention() {
      this.form.intention = this.intentionText.split(/\n/, -1).filter(v => v);
      this.intentionText = this.intentionText.replace(/\n$/, "");
    }
  }
};
</script>

<style></style>
