<template>
  <b-modal
    id="copy-links-to-topic"
    ref="modal"
    title="Copy Link to Topic"
    @ok="duplicateLinksToTopic"
    ok-variant="primary"
    ok-title="Copy"
    :ok-disabled="!selectedDuplicateTopic || !selectedLinkIds.length"
    @hidden="reset"
  >
    <div class="d-flex flex-row">
      <multiselect
        v-model="selectedDuplicateTopic"
        :options="topics"
        placeholder="Select topic"
        label="name"
        track-by="topicId"
        :allow-empty="false"
      ></multiselect>
    </div>
  </b-modal>
</template>

<script>
import { TopicLinksService } from "@/core/services"

import { mapGetters } from "vuex"
import _ from "lodash"
export default {
  name: "CopyLinkModal",
  props: {
    topicId: String,
  },
  data() {
    return {
      selectedDuplicateTopic: null,
    }
  },
  computed: {
    ...mapGetters(["topicData"]),
    topics() {
      return _.orderBy(
        this.topicData("topics").filter(
          (v) => v.name && v.topicId !== this.topicId
        ),
        "created_at",
        "desc"
      )
    },
    selectedLinkIds() {
      return this.topicData("copyLinkIds")
    },
  },
  methods: {
    reset() {
      this.selectedDuplicateTopic = null
    },
    async duplicateLinksToTopic(e) {
      e.preventDefault()
      let response = await TopicLinksService.duplicateLinksToTopic({
        topicId: this.selectedDuplicateTopic.topicId,
        linkIds: this.selectedLinkIds,
      })
      if (!response.error) {
        this.selectedDuplicateTopic = null
        this.$root.$bvToast.toast("Duplicate links to topic success", {
          title: `Success`,
          variant: "success",
          solid: true,
        })
        this.$root.$bvModal.hide("copy-links-to-topic")
      }
    },
  },
}
</script>

<style></style>
