<template>
  <div>
    <div class="row mt-5">
      <div class="col-12">
        <div class="d-flex justify-content-between">
          <div>
            <b-form-select
              v-model="selectedBrand"
              :options="brands"
              value-field="cusId"
              text-field="name"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>
    <b-row class="mt-5">
      <b-col md="6">
        <KTCard>
          <template v-slot:title>
            <h6 class="mb-0 d-flex flex-row align-items-center">
              <span>Topic List</span>
              <div class="ml-3 text-success d-flex flex-row align-items-center">
                <div
                  style="border-radius:50%;width:5px;height:5px"
                  class="bg-success mr-2"
                ></div>
                <span class="font-size-sm"
                  >{{ activeTopicCount | number_format }} active</span
                >
              </div>
            </h6>
          </template>
          <template v-slot:toolbar>
            <button-add v-b-modal.topic-create></button-add>
          </template>
          <template v-slot:body>
            <b-row class="mb-3">
              <b-col sm="3">
                <b-form-select
                  size="sm"
                  v-model="paging.perPage"
                  :options="[10, 25, 50, 100]"
                >
                </b-form-select>
              </b-col>
              <b-col sm="5" offset-sm="4">
                <b-form-input
                  size="sm"
                  type="text"
                  placeholder="Search"
                  v-model="topicSearch"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-table
              selectable
              select-mode="single"
              selected-variant="dark"
              :busy="!isTopicLoaded"
              bordered
              responsive
              :items="topicDatas"
              :fields="fields"
              show-empty
              @row-selected="clickTopic"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template v-slot:head(name)="data">
                <div style="width:150px">{{ data.label }}</div>
              </template>
              <template v-slot:head(created_at)="data">
                <div style="width:100px">{{ data.label }}</div>
              </template>

              <template v-slot:head(updated_at)="data">
                <div style="width:100px">{{ data.label }}</div>
              </template>

              <template v-slot:head(last_run_at)="data">
                <div style="width:100px">{{ data.label }}</div>
              </template>

              <template v-slot:empty>
                <center>No data</center>
              </template>

              <template v-slot:cell(no)="row">
                {{ iteration + row.index + 1 }}
              </template>

              <template v-slot:cell(name)="row">
                {{row.item.brand ? `[${row.item.brand}] `:''}} {{row.item.name}}
              </template>

              <template v-slot:cell(isActive)="row">
                <center>
                  <badge-status :active="row.item.isActive"></badge-status>
                </center>
              </template>
              <template v-slot:cell(actions)="row">
                <div style="width:100px">
                  <button-edit
                    @click="setSelectedTopic(row.item)"
                    v-b-modal.topic-edit
                  >
                    <i class="flaticon2-edit icon-md"></i>
                  </button-edit>

                  <button-delete
                    v-b-modal.delete-topic
                    @click="selectedTopic = row.item"
                  >
                    <i class="flaticon2-trash icon-md"></i>
                  </button-delete>
                </div>
              </template>
            </b-table>
            <div
              class="d-flex flex-row justify-content-between align-items-center"
            >
              <span v-if="paging"
                >{{ paging.totalRows | number_format }} records</span
              >

              <b-pagination
                v-if="paging.numberOfPages"
                v-model="paging.currentPage"
                :total-rows="paging.totalRows"
                align="right"
                :number-of-pages="paging.numberOfPages"
                :per-page="paging.perPage"
                use-router
                @change="changePage"
              ></b-pagination>
            </div>
          </template>
        </KTCard>
      </b-col>

      <b-col md="6" v-if="selectedTopic && isSelectedTopic">
        <TopicLink
          :selected-topic="selectedTopic"
          :expand.sync="isExpand"
        ></TopicLink>
      </b-col>
    </b-row>

    <b-modal
      scrollable
      hide-footer
      size="md"
      id="brand-topics"
      :title="`Topic of ${selectedTopic.name}`"
      v-if="selectedTopic"
    >
      <p v-for="(topic, i) of selectedTopic.topics" :key="i">
        {{ i + 1 }}. {{ topic.name }}
      </p>
    </b-modal>

    <confirm-delete
      v-if="selectedTopic"
      modal-id="delete-topic"
      :remove-fn="removeTopic"
      @ok="getTopics"
    ></confirm-delete>

    <TopicCreate
      :selected-brand="selectedBrand"
      v-if="selectedBrand && isReady"
      @success="resetTopic"
    ></TopicCreate>
    <TopicEdit
      :selected-topic="selectedTopic"
      :selected-brand="selectedBrand"
      v-if="selectedBrand && selectedTopic"
      @success="resetTopic"
    ></TopicEdit>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import { SET_SHARE_DATA } from "@/core/services/store/share.module"
import {
  SET_ALL_TOPICS,
  SET_INTENTIONS,
  SET_COPY_INFO
} from "@/core/services/store/topic.module"
import { TopicService, CustomerService, UtilsService } from "@/core/services"
import KTCard from "@/view/content/Card.vue"

import moment from "moment"
import TopicCreate from "./TopicCreate.vue"
import TopicEdit from "./TopicEdit.vue"

import _ from "lodash"
import TopicLink from "./TopicLink.vue"
import { mapGetters } from "vuex"

export default {
  name: "Topic",
  components: {
    KTCard,
    TopicCreate,
    TopicEdit,
    TopicLink
  },
  data() {
    return {
      isReady: false,
      isExpand: false,
      isSelectedTopic: false,
      isTopicLoaded: false,

      activeTopicCount: 0,
      paging: {
        perPage: 10,
        currentPage: 1,
        totalRows: 0,
        numberOfPages: 0
      },
      selectedBrand: null,
      topicDatas: [],
      brands: [],
      showBrand: null,

      topics: [],
      selectedTopic: null,

      fields: [
        {
          key: "no",
          label: "#",
          class: "text-center"
        },
        {
          key: "name",
          label: "Topic Name"
        },
        {
          key: "isActive",
          label: "Status",
          class: "text-center"
        },
        {
          key: "created_at",
          label: "Created At",
          thStyle: {
            width: "100px"
          }
        },
        {
          key: "updated_at",
          label: "Updated At",
          thStyle: {
            width: "100px"
          }
        },
        {
          key: "last_run_at",
          label: "Last Run At",
          thStyle: {
            width: "100px"
          }
        },

        {
          key: "actions",
          label: "Action",
          class: "text-center"
        }
      ],
      topicSearch: null,
      topicLinkSearch: null,
      interval: null
    }
  },
  mounted() {
    this.$store.dispatch(SET_COPY_INFO, [])
    localStorage.removeItem("auto-update")
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Topics" }])
    this.$store.dispatch(SET_INTENTIONS)

    this.paging.currentPage = Number(this.$route.query.page) || 1
    this.getBrands().then(() => {
      this.selectedBrand =
        this.shareData("topicBrandSelectId") || this.brands[0].cusId
    })

    this.checkAutoUpdate()
    this.interval = setInterval(() => {
      this.checkAutoUpdate()
    }, 10000)
  },
  destroyed() {
    if (this.interval) {
      clearInterval(this.interval)
      this.$root.$bvToast.hide("auto-update-status")
    }
  },
  computed: {
    ...mapGetters(["shareData"]),
    iteration() {
      return this.paging.perPage * (this.paging.currentPage - 1)
    }
  },
  watch: {
    topicSearch: _.debounce(function() {
      this.paging.currentPage = 1
      this.getTopics()
    }, 500),
    async selectedBrand() {
      this.isExpand = false
      this.paging.currentPage = 1
      this.isSelectedTopic = false
      this.selectedTopic = null
      this.$store.dispatch(SET_SHARE_DATA, {
        topicBrandSelectId: this.selectedBrand
      })
      await this.getTopics()
      await this.$store.dispatch(SET_ALL_TOPICS, {})
      this.isReady = true
    },
    "paging.perPage"() {
      this.paging.currentPage = 1
      this.getTopics()
    }
  },
  methods: {
    setSelectedTopic(topic) {
      this.$store.dispatch(SET_COPY_INFO, [])
      this.selectedTopic = null
      this.$nextTick(() => {
        this.selectedTopic = topic
      })
    },
    async resetTopic() {
      this.selectedTopic = null
      this.isSelectedTopic = false
      await this.getTopics()
      await this.$store.dispatch(SET_ALL_TOPICS, {})
    },
    changePage(page) {
      this.selectedTopic = null
      this.isSelectedTopic = false
      this.paging.currentPage = page
      this.getTopics()
    },
    getBrands() {
      return CustomerService.get({
        limit: 0,
        order_by: { orderNumber: 1 }
      }).then(response => {
        this.brands = response.results
      })
    },
    getTopics() {
      this.isTopicLoaded = false

      return TopicService.get({
        cusId: this.selectedBrand,
        offset: this.paging.perPage * (this.paging.currentPage - 1),
        limit: this.paging.perPage,
        q: this.topicSearch
      }).then(topics => {
        this.activeTopicCount = topics.active
        this.paging.totalRows = topics.total
        this.paging.numberOfPages = Math.ceil(
          this.paging.totalRows / this.paging.perPage
        )
        let topicsData = topics.results || []
        this.topicDatas = topicsData.map(v => {
          return {
            ...v,
            created_at: moment(v.created_at).format("DD/MM/YYYY HH:mm"),
            updated_at: moment(v.updated_at).format("DD/MM/YYYY HH:mm"),
            last_run_at: v.last_run_at
              ? moment(v.last_run_at).format("DD/MM/YYYY HH:mm")
              : moment(v.updated_at).format("DD/MM/YYYY HH:mm")
          }
        })
        this.isTopicLoaded = true
      })
    },

    removeTopic() {
      return TopicService.delete({
        cusId: this.selectedBrand,
        topicId: this.selectedTopic.topicId
      })
    },
    clickTopic(topic) {
      this.isSelectedTopic = true
      this.selectedTopic = null
      this.$store.dispatch(SET_COPY_INFO, [])
      this.$nextTick(() => {
        this.selectedTopic = topic[0]
      })
    },
    async checkAutoUpdate() {
      let response = await UtilsService.getAutoUpdateStatus()

      if (!response.error && response.messages_ready > 0) {
        if (localStorage.getItem("auto-update") !== "1") {
          localStorage.setItem("auto-update", "1")
          this.$root.$bvToast.toast("Topic is updating...", {
            id: "auto-update-status",
            title: `Auto update topic`,
            variant: "info",
            noAutoHide: true,
            solid: true
          })
        }
      } else {
        localStorage.removeItem("auto-update")
        this.$root.$bvToast.hide("auto-update-status")
      }
    }
  }
}
</script>
