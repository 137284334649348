<template>
  <b-modal
    id="export-range-engagement-topic"
    ref="modal"
    title="Export engagement by date range"
  >
    <el-date-picker
      :editable="false"
      :clearable="false"
      class="w-100"
      v-model="dateRange"
      type="datetimerange"
      align="left"
      size="small"
      start-placeholder="Start Date"
      end-placeholder="End Date"
      format="dd/MM/yyyy HH:mm"
      value-format="timestamp"
    >
    </el-date-picker>
    <template #modal-footer>
      <div class="text-right">
        <b-button
          variant="primary"
          ref="export_btn"
          @click="downloadRangeEngagement"
          >Export</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import { TopicLinksService } from "@/core/services";
import moment from "moment";
import FileSaver from "file-saver";
export default {
  name: "ExportRangeEngagement",
  props: {
    topicId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dateRange: [
        moment()
          .subtract(1, "hour")
          .startOf("hour")
          .valueOf(),
        moment()
          .endOf("hour")
          .valueOf()
      ]
    };
  },
  methods: {
    async downloadRangeEngagement(e) {
      e.preventDefault();
      this.$refs["export_btn"].classList.add(
        "spinner",
        "spinner-light",
        "spinner-right",
        "disabled"
      );
      let blob = await TopicLinksService.downloadRangeEngagement({
        topicId: this.topicId,
        fromHourly: moment(this.dateRange[0])
          .startOf("hour")
          .valueOf(),
        toHourly: moment(this.dateRange[1])
          .startOf("hour")
          .valueOf()
      });
      FileSaver.saveAs(blob.blob, decodeURIComponent(blob.filename));
      this.$refs["export_btn"].classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right",
        "disabled"
      );
    }
  }
};
</script>

<style></style>
