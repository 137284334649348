<template>
  <b-modal
    size="md"
    id="force-update"
    title="Force Update Engagement"
    ok-title="Save"
    @ok="submit"
    @hidden="$emit('hidden')"
  >
    <b-form-group>
      <label>Link Id</label>
      <b-form-input
        size="sm"
        type="text"
        :value="linkInfo.linkId"
        readonly
        disabled
      ></b-form-input>
    </b-form-group>
    <b-form-group>
      <label>Link</label>
      <b-form-input
        size="sm"
        type="text"
        :value="linkInfo.link"
        readonly
        disabled
      ></b-form-input>
    </b-form-group>
    <b-form-group>
      <b-row>
        <b-col sm="3">
          <label><i class="mr-1 flaticon-like"></i>Like</label>
          <b-form-input
            size="sm"
            type="number"
            :min="linkInfo.recentEngagement.like"
            v-model="recentEngagement.like"
          ></b-form-input>
        </b-col>
        <b-col sm="3">
          <label><i class="mr-1 flaticon-comment"></i>Comment</label>
          <b-form-input
            size="sm"
            type="number"
            :min="linkInfo.recentEngagement.comment"
            v-model="recentEngagement.comment"
          ></b-form-input>
        </b-col>
        <b-col sm="3">
          <label><i class="mr-1 flaticon-share"></i>Share/Retweet</label>
          <b-form-input
            size="sm"
            type="number"
            :min="linkInfo.recentEngagement.retweet"
            v-model="recentEngagement.retweet"
          ></b-form-input>
        </b-col>
        <b-col sm="3">
          <label><i class="mr-1 flaticon-eye"></i>View</label>
          <b-form-input
            size="sm"
            type="number"
            :min="linkInfo.recentEngagement.view"
            v-model="recentEngagement.view"
          ></b-form-input>
        </b-col>
      </b-row>
    </b-form-group>
  </b-modal>
</template>

<script>
import { LinkInfosService } from "@/core/services/linkInfos.service"
export default {
  props: {
    linkInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      recentEngagement: {
        like: this.linkInfo.recentEngagement.like,
        retweet: this.linkInfo.recentEngagement.retweet,
        comment: this.linkInfo.recentEngagement.comment,
        view: this.linkInfo.recentEngagement.view,
      },
    }
  },
  methods: {
    async submit(e) {
      e.preventDefault()

      let data = {
        recentEngagement: {
          like: Number(this.recentEngagement.like),
          retweet: Number(this.recentEngagement.retweet),
          comment: Number(this.recentEngagement.comment),
          view: Number(this.recentEngagement.view),
        },
        link: this.linkInfo.link,
        source: this.linkInfo.source,
      }

      let response = await LinkInfosService.forceUpdate(data)
      if (response.error) {
        this.$root.$bvToast.toast("Update linkinfo fail", {
          title: `Error`,
          variant: "error",
          solid: true,
        })
      } else {
        this.$root.$bvToast.toast("Update linkinfo success", {
          title: `Success`,
          variant: "success",
          solid: true,
        })
        this.$emit("success")
      }
      this.$bvModal.hide("force-update")
    },
  },
}
</script>

<style></style>
