<template>
  <b-card no-body class="p-3" border-variant="primary">
    <strong>Similar Link</strong>
    <div v-for="(link, i) in suggestedLinks" :key="link.link" :class="{'text-danger':link.isMatch || link.isPartialMatch}">
      {{ i + 1 }}.
      <a :href="link.link" target="_blank" rel="noopener noreferrer" :class="{'text-danger':link.isMatch || link.isPartialMatch}" >{{
        link.link
      }}</a>
    </div>
    <div class="mt-2 text-right">
      <b-button size="sm" variant="primary" @click="$emit('resolve')"
        >Resolve</b-button
      >
    </div>
  </b-card>
</template>

<script>
export default {
  name: "SimilarLink",
  props: {
    suggestedLinks: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style></style>
