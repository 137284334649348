<template>
  <b-modal
    size="md"
    id="topic-link-create"
    title="Add New Topic Link"
    ok-title="Save"
    @ok="submit"
    :ok-disabled="isSubmit"
    @hidden="resetForm"
  >
    <b-form autocomplete="off">
      <b-form-group
        label="Select Facebook Post Type"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-radio-group
          id="radio-group-1"
          v-model="selectedPostType"
          :options="postTypes"
          :aria-describedby="ariaDescribedby"
          name="radio-options"
        ></b-form-radio-group>
      </b-form-group>

      <b-form-group>
        <label for="link">Link <span class="text-danger">*</span></label>
        <div class="input-icon input-icon-right">
          <b-form-input
            ref="link"
            size="sm"
            v-model="form.link"
            id="link"
            @change="onChangeLink"
          ></b-form-input>
          <span v-if="resolveState === 'resolving'"
            ><i class="spinner spinner-info mr-5 "></i
          ></span>
          <span v-if="resolveState === 'success'"
            ><i class="flaticon2-check-mark text-success"></i
          ></span>
          <span v-if="resolveState === 'exist'"
            ><i class="text-danger flaticon-exclamation-1"></i
          ></span>
        </div>
      </b-form-group>
      <b-form-group v-if="similarLinks.length">
        <b-row>
          <b-col>
            <SimilarLink
              v-if="resolveState === 'ready'"
              :suggested-links="similarLinks"
              @resolve="resolve"
            ></SimilarLink>
          </b-col>
        </b-row>
      </b-form-group>
      <b-form-group>
        <div class="d-flex flex-row">
          <b-form-checkbox
            id="isPersonal"
            v-model="form.isPersonal"
            :value="true"
            :unchecked-value="false"
          >
            Personal Post
          </b-form-checkbox>

          <b-form-checkbox
            class="ml-3"
            id="facebookGroup"
            v-model="form.isFacebookGroup"
            :value="true"
            :unchecked-value="false"
          >
            Facebook Group
          </b-form-checkbox>
        </div>
      </b-form-group>
      <b-form-group>
        <b-row>
          <b-col sm="4">
            <label for="source"
              >Source <span class="text-danger">*</span></label
            >

            <b-select
              :options="sources"
              size="sm"
              v-model="form.source"
              id="source"
            ></b-select>
          </b-col>
          <b-col sm="4">
            <label for="username"
              >Username <span class="text-danger">*</span></label
            >

            <b-form-input
              size="sm"
              type="text"
              v-model="form.username"
              id="username"
            ></b-form-input>
          </b-col>

          <b-col sm="4">
            <label for="pageName">Page Name</label>
            <b-form-input
              size="sm"
              type="text"
              v-model="form.pageName"
              id="pageName"
            ></b-form-input>
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group>
        <b-row>
          <b-col>
            <label for="postDay"
              >Post Date <span class="text-danger">*</span></label
            >
            <div class="d-flex flex-row align-items-center">
              <el-date-picker
                v-model="form.postDate"
                type="datetime"
                size="sm"
                placeholder="Select date and time"
                format="dd/MM/yyyy HH:mm"
                value-format="timestamp"
                :clearable="false"
              >
              </el-date-picker>
            </div>
          </b-col>
          <b-col>
            <label for="fanCount">Fan Count</label>
            <b-form-input
              size="sm"
              type="text"
              v-model="form.fanCount"
              id="fanCount"
            ></b-form-input>
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group>
        <b-row>
          <b-col sm="3">
            <label><i class="mr-1 flaticon-like"></i>Like</label>
            <b-form-input
              size="sm"
              type="number"
              min="0"
              v-model="form.recentEngagement.like"
            ></b-form-input>
          </b-col>
          <b-col sm="3">
            <label><i class="mr-1 flaticon-comment"></i>Comment</label>
            <b-form-input
              size="sm"
              type="number"
              min="0"
              v-model="form.recentEngagement.comment"
            ></b-form-input>
          </b-col>
          <b-col sm="3">
            <label><i class="mr-1 flaticon-share"></i>Share/Retweet</label>
            <b-form-input
              size="sm"
              type="number"
              min="0"
              v-model="form.recentEngagement.retweet"
            ></b-form-input>
          </b-col>
          <b-col sm="3">
            <label><i class="mr-1 flaticon-eye"></i>View</label>
            <b-form-input
              size="sm"
              type="number"
              min="0"
              v-model="form.recentEngagement.view"
            ></b-form-input>
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group>
        <label for="text">Text <span class="text-danger">*</span></label>

        <b-form-textarea
          type="text"
          size="lg"
          v-model="form.text"
          id="text"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group>
        <label for="intention">Intention</label>

        <b-select
          :options="intentionOpts"
          v-model="form.intention"
          id="intention"
        ></b-select>
      </b-form-group>

      <b-form-group>
        <label for="relatedTopics">Related Topics</label>

        <multiselect
          disabled
          v-model="form.relatedTopic"
          :multiple="true"
          :options="topicData('topics')"
          :searchable="true"
          :close-on-select="false"
          :show-labels="true"
          :hide-selected="false"
          placeholder="Search topic"
          :limit="3"
          :options-limit="300"
          track-by="topicId"
          label="name"
          id="relatedTopics"
        ></multiselect>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import { required } from "vuelidate/lib/validators"
import { TopicLinksService, UtilsService } from "@/core/services"
import SimilarLink from "@/view/content/custom/SimilarLink"
import { mapGetters } from "vuex"
import moment from "moment"
const isValidPostDate = date => {
  return Number(moment(date).format("YYYY")) > 1970
}
export default {
  name: "TopicLinkCreate",
  props: {
    selectedTopic: {
      type: Object,
      required: true
    }
  },
  components: {
    SimilarLink
  },
  data() {
    return {
      postTypes: [
        { text: "Personal", value: "personal" },
        { text: "Non-Personal", value: null }
      ],
      selectedPostType: null,
      isSubmit: false,
      resolveState: "ready", // ready,resolving,success,exist
      sources: [
        "facebook",
        "twitter",
        "pantip",
        "youtube",
        "website",
        "instagram",
        "tiktok"
      ],
      form: {
        postId: null,
        link: null,
        source: null,
        username: null,
        text: null,
        postDate: null,
        recentEngagement: {
          like: null,
          comment: null,
          retweet: null,
          view: null
        },

        isPersonal: false,
        isActive: true,
        isFirst: true,
        isCountEngagement: true,
        isForceDOMUpdate: false,
        note: null,
        intention: null,
        relatedTopic: [],
        fanCount: null,
        pageName: null,
        isFacebookGroup: false
      },
      intentionOpts: [],
      topics: [
        {
          topicId: "xxx",
          name: "testtt"
        }
      ],
      similarLinks: []
    }
  },
  validations: {
    form: {
      link: { required },
      source: { required },
      username: { required },
      text: { required },
      postDate: { required, isValidPostDate },
      isPersonal: { required },
      recentEngagement: { required }
    }
  },
  mounted() {
    this.intentionOpts = this.selectedTopic.intention
    this.form.intention = this.intentionOpts.includes("Complaint")
      ? "Complaint"
      : this.intentionOpts[0]
    this.form.relatedTopic = this.topicData("topics").filter(v =>
      this.selectedTopic.relatedTopic
        ? this.selectedTopic.relatedTopic.includes(v.topicId)
        : false
    )
  },
  computed: {
    ...mapGetters(["topicData"])
  },
  methods: {
    resetForm() {
      this.resolveState = "ready"
      this.similarLinks = []
      this.form = Object.assign(
        {},
        {
          postId: null,
          link: null,
          source: null,
          username: null,
          text: null,
          postDay: null,
          postTime: null,
          isPersonal: false,
          recentEngagement: {
            like: null,
            comment: null,
            retweet: null,
            view: null
          },

          isActive: true,
          isFirst: true,
          isCountEngagement: true,
          isForceDOMUpdate: false,
          note: null,
          intention: this.intentionOpts.includes("Complaint")
            ? "Complaint"
            : this.intentionOpts[0],
          relatedTopic: this.topicData("topics").filter(v =>
            this.selectedTopic.relatedTopic
              ? this.selectedTopic.relatedTopic.includes(v.topicId)
              : false
          ),
          fanCount: null
        }
      )
    },
    async submit(e) {
      this.isSubmit = true
      e.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.isSubmit = false
        return
      }
      let data = Object.assign({}, this.form)
      data.topicId = this.selectedTopic.topicId
      data.relatedTopic = this.selectedTopic.relatedTopic || []
      data.recentEngagement = {
        like: Number(data.recentEngagement.like),
        comment: Number(data.recentEngagement.comment),
        retweet: Number(data.recentEngagement.retweet),
        view: Number(data.recentEngagement.view)
      }

      let response = await TopicLinksService.create(data)
      if (!response.error) {
        this.$emit("success")
        this.$root.$bvToast.toast("Create topic link success", {
          title: `Success`,
          variant: "success",
          solid: true
        })
        this.resetForm()
      }
      this.isSubmit = false
      this.$refs.link.focus()
    },
    async onChangeLink() {
      let link = decodeURI(this.form.link)
      this.resolveState = "ready"
      this.resetForm()
      this.form.link = link

      this.similarLinks = []
      let response = await UtilsService.getTopicLinkSuggestion({
        topicId: this.selectedTopic.topicId,
        link: this.form.link,
        postType: this.selectedPostType
      })
      if (!response.error) {
        this.similarLinks = response.links
        this.form.link = response.redirect_url
        this.form.postId = response.postId
      } else {
        if (response.error.message) {
          this.$root.$bvToast.toast(
            response.error.data.message || response.error.message,
            {
              title: `Error`,
              variant: "danger",
              solid: true
            }
          )
        } else {
          this.$root.$bvToast.toast("Internal Server Error", {
            title: `Error`,
            variant: "danger",
            solid: true
          })
        }
      }

      if (!this.similarLinks.length && !response.error) {
        await this.resolve()
      }
    },
    async resolve() {
      this.similarLinks = []
      this.resolveState = "resolving"

      if (!this.form.link) {
        this.resetForm()
        return
      }

      let response = await TopicLinksService.resolve({
        topicId: this.selectedTopic.topicId,
        link: this.form.link,
        postId: this.form.postId
      })

      let link = this.form.link

      if (!response.error) {
        this.resolveState = "success"

        this.form = {
          ...this.form,
          link: response.link,
          source: response.source,
          username: response.username,
          text: response.text,
          postDate: response.postDate,
          recentEngagement: {
            like: response.like,
            comment: response.comment,
            retweet: response.retweet,
            view: response.view
          },
          isPersonal: response.isPersonal,
          isActive: true,
          isFirst: true,
          isCountEngagement: true,
          isForceDOMUpdate: false,
          postId: response.postId,
          fanCount: response.fanCount,
          isFacebookGroup: response.isFacebookGroup,
          pageName: response.pageName
        }
      } else {
        this.$root.$bvToast.toast(response.error.data.message, {
          title: `Error`,
          variant: "danger",
          solid: true
        })

        this.resetForm()
        this.form.link = link
      }
    }
  }
}
</script>

<style></style>
